<template>
    <div class="order-content">
        <div class="head-con">
            <el-tabs @tab-click="handleClick" v-model="tabIndex">
                <el-tab-pane name="1">
                    <template #label>
                        <span>全部订单</span>
                    </template>
                </el-tab-pane>
                <el-tab-pane name="2">
                    <template #label>
                        <span>待付款 {{dfk}}</span>
                    </template>
                </el-tab-pane>
                <el-tab-pane name="3">
                    <template #label>
                        <span>待发货 {{dfh}}</span>
                    </template>
                </el-tab-pane>
                <el-tab-pane name="4">
                    <template #label>
                        <span>待收货 {{dsh}}</span>
                    </template>
                </el-tab-pane>
                <el-tab-pane name="5">
                    <template #label>
                        <span>待评价 {{dpj}}</span>
                    </template>
                </el-tab-pane>
            </el-tabs>
            <el-input v-model.trim="input3" placeholder="请输入要搜索的订单号" size="mini">
                <template #append>
                    <el-button @click="searchValue">搜索</el-button>
                </template>
            </el-input>
        </div>

        <div class="body-con" v-if="order">
            <div class="info-con">
                <el-row>
                    <el-col :span="7"><div class="item">商品信息</div></el-col>
                    <el-col :span="4"><div class="item">单价</div></el-col>
                    <el-col :span="4"><div class="item">数量</div></el-col>
                    <el-col :span="3"><div class="item">实付款</div></el-col>
                    <el-col :span="3"><div class="item">订单状态</div></el-col>
                    <el-col :span="3"><div class="item">操作</div></el-col>
                </el-row>
            </div>

            <div class="goods-con" v-for="item in order" :key="item.id">
                <div class="goods-head">
                    <div class="item">
                        <p>店铺名：</p>
                        <span>{{item.shop_name}}</span>
                    </div>
                    <div class="item">
                        <p>订单号：</p>
                        <span>{{item.sNo}}</span>
                    </div>
                    <div class="item">
                        <p>下单时间：</p>
                        <span>{{item.add_time}}</span>
                    </div>
                </div>
                <el-table :data="item.list" style="width: 100%" :show-header="false" :border="true">
                    <el-table-column width="320" align="center">
                        <template #default="scope">
                            <div class="name-wrapper">
                                <img :src="scope.row.imgurl" class="good-img"/>
                                <div class="info-con1">
                                    <p>{{scope.row.p_name}}</p>
                                    <span>{{scope.row.size}}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="130" align="center">
                        <template #default="scope">
                             <p class="price">￥{{scope.row.p_price}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column width="130" align="center">
                        <template #default="scope">
                           <div class="info-cons">
                                <p>{{scope.row.num}}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="130" align="center">
                        <template #default="scope">
                            <div class="info-con1">
                                <p>￥{{scope.row.after_discount}}</p>
                                <span>(含运费：{{scope.row.freight}})</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" align="center">
                        <span v-if="item.status == 0" class="red">待付款</span>
                        <span v-else-if="item.status == 1">待发货</span>
                        <span v-else-if="item.status == 2">待收货</span>
                        <span v-else-if="item.status == 3">待评论</span>
                        <span v-else-if="item.status == 4" style="white-space: nowrap;">
                            {{item.list[0].re_type==1?'退货退款':item.list[0].re_type==2?'退款':'换货'}}
                            :
                            {{item.list[0].r_type==0?'审核中':item.list[0].r_type==1?'审核通过':item.list[0].r_type==2?'拒绝':item.list[0].r_type==3?'已回寄':item.list[0].r_type==4?'成功':item.list[0].r_type==5?'拒绝':item.list[0].r_type==8?'拒绝':item.list[0].r_type==9?'成功':item.list[0].r_type==10?'拒绝':item.list[0].r_type==11?'成功':'售后结束'}}
                        </span>
                        <span v-else-if="item.status == 5">交易成功</span>
                        <span v-else-if="item.status == 6" class="grey">订单失效</span>
                        <span v-else-if="item.status == 7" class="grey">交易暂时关闭</span>
                        <span v-else-if="item.status == 12">已完成</span>

                        <p v-if="item.status != 4">订单详情</p>
						<p v-else>售后详情</p>
                    </el-table-column>

                    <el-table-column align="center">
                        <div v-if="item.status == 0" class="waiting-to-pay">
							<div>
								<i class="el-icon-time" style="transform: rotateY(180deg);"></i>
								{{item.countdown}}
							</div>
							<el-button size="small" type="danger" @click="toUrl('order','payment','&order_id='+item.id)">立即付款</el-button>
							<span class="sp" @click="orderBtn(item.id,'removeOrder')">取消订单</span>
						</div>
						<div v-else-if="item.status ==7" class="waiting-to-pay">
							<el-button size="small" type="danger" @click="toUrl('order','payment','&order_id='+item.id)">重新付款</el-button>
						</div>
						<el-button size="small" :disabled="item.delivery_status==1" v-else-if="item.status == 1" type="danger" @click="orderBtn(item.id,'delivery_delivery')">提醒发货</el-button>
						<el-button size="small" v-else-if="item.status == 2 && item.sale_type == 0 && item.self_lifting!=1" type="danger" @click="orderBtn(item.id,'ok_Order')">确认收货</el-button>
						<el-button size="small" v-else-if="item.status == 5" @click="toUrl('order','evaluationing','&sNo='+item.sNo+'&type=5')">查看评论</el-button>
						<el-button size="small" v-else-if="item.self_lifting==1" type="danger" @click="View_tqm(item)">查看提取码</el-button>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="noOrder" v-else>
            <img src="@/assets/image/no_order.png" />
            <p>你还没有相关的订单哟~</p>
        </div>
    </div>
</template>
<script>
import { onMounted,reactive,ref, toRefs} from 'vue'
import { getData,_post1 } from '@/utils/req'
import { ElMessage } from 'element-plus'
export default {
    setup(){
        let pc_user = ref('')
        let activeName = ref(0)
        let input3 = ref(null)
        let state = reactive({
            order:null,
            order_num:null,
            order_failure:null,
            company:null,
            dfh:null,
            dfk:null,
            dsh:null,
            dpj:null,
        })
        let tabIndex = ref("1")
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const axios = ()=>{
            const data = {
                module: 'app_pc',
                action: 'order',
                m: 'index',
                access_id:pc_user.value.access_id,
                ordervalue: '',
                page: 1
            }
            getData(data).then(res=>{
                state.company = res.data.company
                state.order_num = res.data.order_num
                state.order = res.data.order
                state.order_failure = res.data.order_failure
                state.dfk = res.data.res_order[0]
                state.dfh = res.data.res_order[1]
                state.dsh = res.data.res_order[2]
                state.dpj = res.data.res_order[3]
            })
        }
        const handleClick = (tab)=>{
            const data ={
                access_id: pc_user.value.access_id,
                module: 'app_pc',
                action: 'order',
                m: 'index',
                ordervalue: "",
                page: 1
            }
            if(tab.index == 1){
                data["order_type"] = "payment"
            }
            else if(tab.index == 2){
                data["order_type"] = "send"
            }
            else if(tab.index == 3){
                data["order_type"] = "receipt"
            }
            else if(tab.index == 4){
                data["order_type"] = "evaluete"
            }
            _post1(data).then(res=>{
                state.company = res.data.company
                state.order_num = res.data.order_num
                state.order = res.data.order
                state.order_failure = res.data.order_failure
                state.dfk = res.data.res_order[0]
                state.dfh = res.data.res_order[1]
                state.dsh = res.data.res_order[2]
                state.dpj = res.data.res_order[3]
            })
        }
        const orderBtn = (order_id, m) => {
            var data = {
                access_id: pc_user.value.access_id,
                module: 'app_pc',
                action: 'order',
                m,
                order_id
            }
            getData(data).then(res => {
                ElMessage.success({
                    message:res.message,
                    duration:1500
                })
                axios()
            })
		}
        const searchValue = ()=>{
            const data = {
                module: 'app_pc',
                action: 'order',
                m: 'index',
                access_id:  pc_user.value.access_id,
                ordervalue: input3.value,
                page: 1
            }
            getData(data).then(res=>{
                ElMessage.success({
                    message:res.message,
                    duration:1500
                })
                state.company = res.data.company
                state.order_num = res.data.order_num
                state.order = res.data.order
                state.order_failure = res.data.order_failure
                state.dfk = res.data.res_order[0]
                state.dfh = res.data.res_order[1]
                state.dsh = res.data.res_order[2]
                state.dpj = res.data.res_order[3]
            })
        }
        return {
            activeName,
            input3,
            tabIndex,
            ...toRefs(state),
            handleClick,
            orderBtn,
            searchValue
        }
    }
}
</script>
<style scoped lang="scss">
    .order-content{
        background: #fff;
        width: 100%;
        height: 100%;
        .head-con{
            position: relative;
        }
        .body-con{
            width: 100%;
            height: 100%;
            padding-bottom: 30px;
            .info-con{
                width: 940px;
                height: 44px;
                margin: 0px auto ;
                padding-bottom: 20px;
                background: #f6f6f6;
                .item{
                    text-align: center;
                    line-height: 44px;
                    height: 44px;
                    color: #999999;
                    background: #F6F6F6;
                    font-size: 14px;
                }
            }
            .goods-con{
                margin: 20px auto 0;
                padding: 0 10px;
                .goods-head{
                    width: 100%;
                    height: 40px;
                    background: #F6F6F6;
                    border-right: 1px solid #E6E6E6;
                    border-bottom: 1px solid #E6E6E6;
                    display: flex;
                    align-items: center;
                }
                .item{
                    display: flex;
                    align-items: center;
                    color: #666666;
                    font-size: 12px;
                    margin-left: 18px;
                    line-height: 40px;
                }
                .item:first-child{
                    color: #020202;
                }
                .name-wrapper{
                    display: flex;
                    align-items: center;
                    .good-img{
                        width: 65px;
                    }
                }
            }
        }
    }
    .el-input-group{
        width: 290px;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .info-con1{
        margin-left: 5px;
        text-align: left;
    }
    .info-con1 p{
        color: #020202;
        font-size: 14px;
        text-align: left;
    }
    .info-con1 span{
        font-size: 12px;
        color: #999999;
        text-align: left;
    }
    .info-con1 .price{
        color: #606266;
        text-align: center;
    }
    ::v-deep .el-table .el-table__cell{
        padding: 35px 0;
    }
    .sp{
        display: block;
        margin-top: 5px;
    }
    .noOrder{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    ::v-deep .el-tabs__item{
        height: 58px;
        line-height: 58px;
    }
    :deep .el-tabs__nav-wrap::after{
        height: 1px;
    }
    ::v-deep .el-tabs__nav-scroll{
        padding-left: 20px;
    }
    ::v-deep .el-tabs__item.is-active{
        color:#D4282D;
        font-weight: bold;
    }
    ::v-deep .el-tabs__active-bar{
        background: #D4282D;
    }
    ::v-deep .el-tabs__item{
        font-size: 16px;
    }
    ::v-deep .el-input__inner{
        border-radius: 0px;
    }
    ::v-deep .el-input-group__append, .el-input-group__prepend{
        border-radius: 0px;
        cursor: pointer;
    }
    .grey{
        color: #020202;
        font-size: 13px;
        line-height: 26px;
    }
</style>